<template>
  <div class="container-fluid" style="height: 93vh;">
    <!-- For person with authority -->
    <div v-if="isSecretaryRole() || isChairmanRole()">
      <statedForAuthority></statedForAuthority>
    </div>
    <!-- For normal role -->
    <div v-else>
      <statedForPersonal></statedForPersonal>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import StatedForAuthority from "./StatedForAuthority";
import StatedForPersonal from "./StatedForPersonal";
import functionUtils from "utils/functionUtils";
export default {
  components: {
    statedForAuthority: StatedForAuthority,
    statedForPersonal: StatedForPersonal,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["GET_CURRENT_ACCOUNT"]),
  },
  created() {
    let eventId = sessionStorage.getItem("event_id");
    let filter = {
      params: {
        event: eventId ? eventId : null,
      },
    };
    this.SET_USER_DATA(filter);
  },
  methods: {
    /**
     * Check null
     */
    isNull: function(data) {
      return functionUtils.isNull(data);
    },
    /**
     * Check has support role
     */
    isSupportRole: function() {
      return functionUtils.isSupportRole(this.GET_CURRENT_ACCOUNT);
    },
    /**
     * Check has support role
     */
    isSecretaryRole: function() {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT);
    },
    /**
     * Check has chairman role
     */
    isChairmanRole: function() {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT);
    },
    ...mapActions(['SET_USER_DATA'])
  },
};
</script>
<style lang="scss" scoped></style>
