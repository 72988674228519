<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 p-0" style="height: 93vh !important; overflow: auto;">
      <div class="col-md-12 pt-2 pb-3 wh-100">
        <div class="row">
          <div class="col-md-12">
            <b-button
              size="sm"
              class="user-stated float-right pl-4 pr-4"
              @click="onShowSelectUserStated"
            >{{ $t("stated.addUserStated") }}</b-button>
          </div>
        </div>
      </div>
      <div>
        <draggable
          v-model="sentimentList"
          class="list"
          handle=".draggable"
          ghost-class="ghost"
          :move="checkMove"
          @start="dragging = true"
          @end="onEndDragging()"
        >
          <div
            :class="{ draggable: !element.fixed, fixed: element.fixed }"
            v-for="(element, index) in sentimentList"
            :key="element.name"
            style="border-bottom: 1px solid #CCCCCC;"
          >
            <div
              class="sentiment-item"
              :style="
                sentimentItemIndex == index ? 'background-color: #ECEFF0; cursor: pointer;' : 'cursor: pointer;'
              "
              @click="onSelectSentiment(element, index)"
            >
              <!-- Stt -->
              <div class="meeting-font-regular" style="width: 5%;">
                <span class="meeting-font-medium">{{ index + 1 }}</span>
              </div>
              <!-- Fullname and position -->
              <div class="text-left" style="width: 65%;">
                <span class="meeting-font-medium meeting-font-regular" style="color: #000000;">{{ element.fullname }}</span>
                <span
                  class="meeting-font-medium"
                  style="display: block; color: #707070;"
                  >{{
                    getCompanyAndPosition(element.companyName , element.companyPosition)
                  }} - {{ $t('stated.seat') }}: {{element.seatCode}}</span
                >
                <!-- <span class="meeting-font-medium" style="display: block; color: #707070;">{{ $t('stated.seat') }}: {{element.seatCode}}</span> -->
              </div>
              <!-- Status -->
              <div class="meeting-font-regular" style="width: 20%; align-self: center; text-align: center;">
                <span
                  class="meeting-font-small"
                  v-if="element.status == sentimentStatus.DONE"
                  style="color: #26BA50"
                >
                  {{ $t(getStatus(element.status)) }}
                </span>
                <span
                  class="meeting-font-small"
                  v-else-if="element.status == sentimentStatus.CRITICIZE"
                  style="color: #006ad4"
                >
                  {{ $t(getStatus(element.status)) }}
                </span>
                <span
                  class="meeting-font-small"
                  v-else-if="element.status == sentimentStatus.DOING"
                  style="color: #00bcd4"
                >
                  {{ $t(getStatus(element.status)) }}
                </span>
                <span class="meeting-font-small" v-else style="color: #F2404D">
                  {{ $t(getStatus(element.status)) }}
                </span>
              </div>
              <!-- Image -->
              <div style="align-self: center; width: 5%;">
                <img src="../../assets/icons/4_dots.svg" />
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div
      class="col-sm-12 col-md-6 p-0"
      style="background-color: #ECEFF0; height: 93vh;"
    >
      <!-- time countdown -->
      <div 
        v-if="checkShowVoice()"
        style="padding: 5px; display: flex">
        <div class="text-left col-md-6">{{ $t('stated.speaker') }}: {{fullNameUserVoice}}</div>
        <div class="col-md-6">
          <flip-count-down 
            :endTime="endTimeActiveVoice"
            @timeElapsedHandler="updateSentimentAndCriticizeSentiment"
          ></flip-count-down>
        </div>
      </div>
      <!-- end time countdown -->
      <div class="text-left" style="padding: 15px 52px 15px 52px;" v-if="sentimentList.length!=0">
        <!-- user speak -->
        <div class="col-md-12 pb-3 pl-0">
          <div class="row">
            <div class="col-sm-4">
              <span class="meeting-font-large" style="color: #7D7D7D;">
                {{ $t('stated.speaker') }}
              </span>
            </div>
            <div class="col-sm-8">
              <span
                class="meeting-font-large meeting-font-regular pre-text"
                style="display: block"
              >{{sentimentSelected.userCreatedName}}</span>
              <div>
                <p class="m-0 meeting-font-medium">{{$t('register.position')}}: {{sentimentSelected.userCreatedPosition}}</p>
                <p class="m-0 meeting-font-medium">{{$t('register.companyName')}}: {{sentimentSelected.userCreatedCompany}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Content speak -->
        <div class="col-md-12 pb-3 pl-0">
          <div class="row">
            <div class="col-sm-4">
              <span class="meeting-font-large" style="color: #7D7D7D;">
                {{ $t('stated.contentSpeak') }}
              </span>
            </div>
            <div class="col-sm-8">
              <span
                class="meeting-font-large meeting-font-regular pre-text"
                style="display: block"
              >{{sentimentSelected.description}}</span>
            </div>
          </div>
        </div>
        <!-- Time speak -->
        <div class="col-md-12 pl-0">
          <div class="row">
            <div class="col-sm-4">
              <span class="meeting-font-large" style="color: #7D7D7D;">
                {{ $t('support.time') }}
              </span>
            </div>
            <div class="col-sm-8">
              <span
                class="meeting-font-large meeting-font-regular pre-text"
                style="display: block"
              >
              <!-- {{sentimentSelected}} -->
                {{sentimentSelected.timeSpeak 
                  ? (sentimentSelected.timeSpeak.hours > 1
                      ? `${ sentimentSelected.timeSpeak.hours} ${$t('stated.hours')}, `: '')
                    + (sentimentSelected.timeSpeak.minutes > 1
                      ? `${ sentimentSelected.timeSpeak.minutes}  ${$t('stated.minutes')}, `: '')
                    + (sentimentSelected.timeSpeak.seconds > 1
                      ? `${ sentimentSelected.timeSpeak.seconds}  ${$t('stated.seconds')} `: '')
                  : sentimentSelected.startTime}}
                    
                <!-- {{ sentimentSelected.timeSpeak 
                  ? (sentimentSelected.timeSpeak.hours 
                    + ` ${ sentimentSelected.timeSpeak.hours > 1 ? $t('stated.hours') : $t('stated.hour') }, ` 
                    + sentimentSelected.timeSpeak.minutes 
                    + ` ${ sentimentSelected.timeSpeak.minutes > 1 ? $t('stated.minutes') 
                  : $t('stated.minute') } `) : sentimentSelected.startTime }} -->
              </span>
            </div>
          </div>
        </div>
        <!-- Content speak -->
        <!-- <div>
          <span class="meeting-font-large" style="color: #7D7D7D;"
            >{{ $t('stated.contentSpeak') }}</span
          >
          <span
            class="meeting-font-large meeting-font-regular pre-text"
            style="display: block; margin-top: 15px;"
            >{{ sentimentSelected.description }}</span
          >
        </div> -->
        <!-- Time speak -->
        <!-- <div class="mt-35">
          <span class="meeting-font-large" style="color: #7D7D7D;"
            >{{ $t('support.time') }}</span
          >
          <span class="meeting-font-large meeting-font-regular" style="display: block;"
            >{{ sentimentSelected.timeSpeak ? (sentimentSelected.timeSpeak.hours + ` ${ sentimentSelected.timeSpeak.hours > 1 ? $t('stated.hours') : $t('stated.hour') }, ` + sentimentSelected.timeSpeak.minutes + ` ${ sentimentSelected.timeSpeak.minutes > 1 ? $t('stated.minutes') : $t('stated.minute') } `) : '' }}</span
          >
        </div> -->
        <div class="mt-35">
          <!-- Allow speak -->
          <div v-if="sentimentSelected.status == sentimentStatus.WAITING"
            :class="`${checkUserSpeakingOrCrimticism(sentimentSelected) ? 'btn-allow-speak-disable' : 'btn-allow-speak'}`">
            <span @click="checkUserSpeakingOrCrimticism(sentimentSelected) ? '' : sentimentManipulation(sentimentActionType.ALLOW)" class="meeting-font-small meeting-font-regular" style=""
              >{{ $t('stated.speechPermission') }}</span
            >
          </div>
          <!-- Reject speak -->
          <div v-if="sentimentSelected.status == sentimentStatus.WAITING" @click="sentimentManipulation(sentimentActionType.NOT_ALLOW)" class="btn-reject-end" style="margin-right: 5px; cursor: pointer;">
            <span class="meeting-font-small meeting-font-regular" style="color: #F2404D;"
              >{{ $t('stated.speechNotAllowed') }}</span
            >
          </div>
          <!-- End speak -->
          <div v-if="sentimentSelected.status == sentimentStatus.DOING">
            <div @click="sentimentManipulation(sentimentActionType.CRITICIZE)" class="btn-criticize" style="cursor: pointer;">
              <span class="meeting-font-small meeting-font-regular" style="color: #006ad4;"
                >{{ $t('stated.criticize') }}</span
              >
            </div>
            <div @click="sentimentManipulation(sentimentActionType.END)" class="btn-reject-end ml-2" style="cursor: pointer;">
              <span class="meeting-font-small meeting-font-regular" style="color: #F2404D;"
                >{{ $t('stated.close') }}</span
              >
            </div>
          </div>
          <!-- btn end speak -->
          <div v-if="sentimentSelected.status == sentimentStatus.CRITICIZE" @click="sentimentManipulation(sentimentActionType.END)" class="btn-reject-end" style="margin-right: 5px; cursor: pointer;">
            <span class="meeting-font-small meeting-font-regular" style="color: #F2404D;"
              >{{ $t('stated.close') }}</span
            >
          </div>
        </div>
      </div>
      <!-- criticism list -->
      <div class="p-2" v-if="sentimentList.length!=0">
        <criticizeSentimentList
          :sentimentId="sentimentSelected.id"
          :sentimentSpeakingStatus="sentimentSelected.status"
          :isManager="true"
          ref="criticismModal"
          style="margin-top: 15px" />
      </div>
    </div>
    <loading-bar :isLoading="isLoading"></loading-bar>
    <!--  -->
    <b-modal
      id="addUserStatedModal"
      ref="addUserStatedModal"
      :title="$t('sideBar.stated')"
      centered>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <span style="font-size: 16px;">{{
                $t("stated.addUserStated")
              }}</span>
              <VueMutiselect
              :optionData="optionData"
              :customLabel="customLabel"
              :labelText="labelTextValue"
              :trackByText="trackByTextValue"
              :placeHolder="$t('mission.selectPerson')"
              :multipleValue="false"
              @select="getAssignerSelect"
            ></VueMutiselect>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <span style="font-size: 16px;">{{
                $t("stated.enterContentRegisterSpeak")
              }}</span>
              <textarea
                v-model="addUserStated.description"
                class="form-control meeting-font-regular mt-3"
                style="font-size: 16px;"
                :placeholder="$t('stated.enterContentSpeak')"
                rows="3"
              ></textarea>
            </div>
          </div> -->
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="user-stated float-right"
            @click="sendRegisterStated"
          >
            {{ $t('stated.sendRegister') }}
          </b-button>
          <b-button
            size="sm"
            class="float-right mr-2"
            @click="$bvModal.hide('addUserStatedModal')"
          >
            {{ $t('common.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import dateUtils from 'utils/dateUtils'
import SentimentStatus from "enum/sentimentStatus";
import SentimentActionType from 'enum/sentimentActionType'
import functionUtils from 'utils/functionUtils'
import Enum from 'enum/enum';
import VueMutiselect from "Components/VueMutiselect";
import criticizeSentimentList from "./CriticizeSentimentList";
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
import FlipCountDown from 'Components/FlipCountDown'
import moment from 'moment';

export default {
  components: {
    criticizeSentimentList,
    draggable,
    VueMutiselect,
    FlipCountDown
  },
  data: () => ({
    enabled: true,
    sentimentList: [],
    dragging: false,
    eventId: null,
    sentimentItemIndex: 0,
    sentimentSelected: {
      description: "",
      dateCreated: "",
      id: 0,
      status: null,
      timeSpeak: null,
      user_id: null,
      userCreatedName: null,
      userCreatedEmail: null,
      userCreatedPosition: null,
      userCreatedCompany: null,
      startTime: null,
      endTime: null
    },
    sentimentStatus: {
      DONE: SentimentStatus.DONE,
      WAITING: SentimentStatus.WAITING,
      CANCELLED: SentimentStatus.CANCELLED,
      DOING: SentimentStatus.DOING,
      ABORTED: SentimentStatus.ABORTED,
      CRITICIZE: SentimentStatus.CRITICIZE
    },
    sentimentActionType: {
      ALLOW: SentimentActionType.ALLOW,
      NOT_ALLOW: SentimentActionType.NOT_ALLOW,
      END: SentimentActionType.END,
      CRITICIZE: SentimentActionType.CRITICIZE
    },
    addUserStated: {
      description: null,
      userId: null
    },
    optionData: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    activeVoiceConfig: 0,
    endTimeActiveVoice: null,
    fullNameUserVoice: null
  }),
  computed: {
    ...mapGetters([
      "SENTIMENT_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "EVENT_DETAIL_DATA",
      "GET_LAST_ACTIVE_VOICE_DATA"]),
  },
  watch: {
    SENTIMENT_DATA: async function() {
      this.sentimentList = [];
      this.SENTIMENT_DATA &&
        this.SENTIMENT_DATA.forEach((element, index) => {
          let object = {
            fixed: this.getFixed(element.status),
            id: element.id,
            stt: index + 1,
            email: element.user.email,
            fullname: element.user.last_name + " " + element.user.first_name,
            companyName: element.company.name,
            companyPosition: element.company.position,
            status: element.status,
            selected: false,
            description: element.description,
            dateCreated: element.date_created,
            timeSpeak: this.getTimeSpeak(element.start_time, element.end_time),
            startTime: !functionUtils.validateStringIsNull(element.start_time)
              ? dateUtils.formatDate(
                element.start_time,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_TIME_DATE) : null,
            endTime: !functionUtils.validateStringIsNull(element.end_time)
              ? dateUtils.formatDate(
                element.end_time,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_TIME_DATE) : null,
            seatCode: element.seat_code,
            user_id: element.user.id
          };
          this.sentimentList.push(object);
        });
      this.isLoading = false
      // Set default
      await this.setDefaultSentiment(this.sentimentList[this.sentimentItemIndex])
    },
    GET_USER_IN_EVENT_LIST_DATA() {
      this.optionData = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let item = {
          id: data.id,
          full_name: full_name,
          url_avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group,
          email: data.email
        };
        this.optionData.push(item);
      });
    },
    EVENT_DETAIL_DATA: function() {
      let data = this.EVENT_DETAIL_DATA
      this.activeVoiceConfig = data.active_voice_time_config
    },
    GET_LAST_ACTIVE_VOICE_DATA: function() {
      let data = this.GET_LAST_ACTIVE_VOICE_DATA
      this.fullNameUserVoice = data.full_name_user
    }
  },
  methods: {
    /**
     * check company name and company position
     */
    getCompanyAndPosition: function(company, position){
      return functionUtils.getCompanyAndPosition(company, position)
    },
    /**
     * Get time speak
     */
    getTimeSpeak: function (startTime, endTime) {
      if (functionUtils.isNull(startTime) || functionUtils.isNull(endTime)) {
        return null
      } else {
        let start = new Date(dateUtils.formatDate(startTime, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, "YYYY-MM-DD HH:mm:ss"))
        let end = new Date(dateUtils.formatDate(endTime, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, "YYYY-MM-DD HH:mm:ss"))
        var timeRemaining = end.getTime() - start.getTime()
        return dateUtils.millisToHoursMinutesAndSeconds(timeRemaining)
      }
    },
    /**
     * Get fixed
     */
    getFixed: function (status) {
      if (status == SentimentStatus.WAITING) {
        return false
      }
      return true
    },
    /**
     * Sentiment maniplutation
     */
    sentimentManipulation: function (actionType) {
      let filter = {
        actionType: actionType,
        isAdminRole: true
      }
      if (actionType === SentimentActionType.ALLOW) {
        this.createActiveVoice()
      }
      if (actionType === SentimentActionType.CRITICIZE ||
        actionType === SentimentActionType.END
      ) {
        this.updateActiveVoice()
      }
      this.UPDATE_SENTIMENT({
        id: this.sentimentSelected.id,
        ...filter,
      }).then(
        function () {
          this.sentimentItemIndex = 0
          // let filter = {
          //   params: {
          //     isGetAll: true,
          //     eventId: this.eventId
          //   },
          // };
          // this.isLoading = true
          // this.GET_SENTIMENT_IN_EVENT(filter).then(
          //   function () {
          //     // Todo
          //   }.bind(this)
          // ).catch(
          //   function () {
          //     this.isLoading = false
          //   }.bind(this)
          // )
          this.refreshData()
          this.sendNotification(actionType)
        }.bind(this)
      )
    },
    /**
     * Set default sentiment
     */
    setDefaultSentiment: function (sentiment) {
      this.sentimentSelected.description = sentiment && sentiment.description;
      this.sentimentSelected.id = sentiment && sentiment.id;
      this.sentimentSelected.dateCreated = sentiment ? dateUtils.formatDate(
        sentiment.dateCreated ? sentiment.dateCreated : sentiment.date_created,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
        dateUtils.STATIC_FORMAT_TIME_DATE
      ) : null
      this.sentimentSelected.status = sentiment && sentiment.status
      this.sentimentSelected.timeSpeak = sentiment && sentiment.timeSpeak
      this.sentimentSelected.user_id = sentiment && sentiment.user_id
      this.sentimentSelected.userCreatedName = sentiment && sentiment.fullname
      this.sentimentSelected.userCreatedEmail = sentiment && sentiment.email
      this.sentimentSelected.userCreatedPosition = sentiment && sentiment.companyPosition
      this.sentimentSelected.userCreatedCompany = sentiment && sentiment.companyName
      this.sentimentSelected.startTime = sentiment && sentiment.startTime
      this.sentimentSelected.endTime = sentiment && sentiment.endTime
    },
    /**
     * Select sentiment
     */
    onSelectSentiment: function(element, index) {
      this.sentimentItemIndex = index;
      this.setDefaultSentiment(element)
    },
    /**
     * Get status
     */
    getStatus: function(status) {
      if (status == SentimentStatus.DONE) {
        return "stated.statusType.done";
      } else if (status == SentimentStatus.WAITING) {
        return "stated.statusType.waiting";
      } else if (status == SentimentStatus.CANCELLED) {
        return "stated.statusType.cancelled";
      } else if (status == SentimentStatus.DOING) {
        return 'stated.statusType.doing';
      } else if (status == SentimentStatus.ABORTED) {
        return "stated.statusType.aborted";
      } else if (status == SentimentStatus.CRITICIZE) {
        return "stated.statusType.criticize";
      }else {
        return ""
      }
    },
    /**
     * End dragging
     */
    onEndDragging: function() {
      this.dragging = false;
      let filter = {
        sentimentList: this.sentimentList,
      };
      this.UPDATE_PRIORITY_SENTIMENT_IN_EVENT(filter);
    },
    /**
     * Check move
     */
    checkMove: function(e) {
      return e.draggedContext
    },
    isDraggable(context) {
      const { index, futureIndex } = context
      return !(this.sentimentList[index].fixed || this.sentimentList[futureIndex].fixed);
    },
    // socket
    webSocketSentiment: function(){
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws"
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL)
      const socket = new WebSocket(
        ws_scheme +
        '://'
        + backEndUrl.host
        + `/${Enum.ChannelsActionCodeEnum.Sentiment}/`
      )
      socket.onmessage = function (event)  {
        if (event.data != undefined){
          let message_data = JSON.parse(event.data)
          if (message_data.message == Enum.ActionEnum.Sentiment){
            let filter = {
              params: {
                eventId: this.eventId,
                isGetAll: true
              },
            };
            this.GET_SENTIMENT_IN_EVENT(filter)
            this.findSentimentSelected()
          }
        }
      }.bind(this)

      socket.onclose = function() {
        // this.connected = false
      }
    },
    findSentimentSelected: function(){
      let index = this.sentimentList.findIndex(x=> x.id == this.sentimentSelected.id)
      if (index != -1){
        this.sentimentItemIndex = index;
        this.setDefaultSentiment(this.sentimentList[index])
      }
    },
    sendNotification(actionType){
      let data = {
        event_id: sessionStorage.getItem("event_id"),
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.CONFIRM_STATED,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.Stated,
        stated_content: this.description,
        user_id: this.sentimentSelected.user_id
      }

      let smsData = {
        event_id: sessionStorage.getItem("event_id"),
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.CONFIRM_STATED,
        template_type: Enum.TemplateType.SMS,
        stated_content: this.description,
        user_id: this.sentimentSelected.user_id
      }
      if(actionType==this.sentimentActionType.ALLOW){
        this.SEND_NOTIFICATION_IN_MEETING(data)
        this.SEND_SMS_IN_MEETING(smsData)
      } else if(actionType==this.sentimentActionType.NOT_ALLOW) {
        data['action_code'] = TemplateCategoriesEnum.REJECT_STATED
        smsData['action_code'] = TemplateCategoriesEnum.REJECT_STATED
        this.SEND_NOTIFICATION_IN_MEETING(data)
        this.SEND_SMS_IN_MEETING(smsData)
      }
    },
    onShowSelectUserStated: function () {
      this.$refs['addUserStatedModal'].show()
      this.addUserStated.userId = null
    },
    sendRegisterStated: function () {
      if (functionUtils.validateStringIsNull(this.addUserStated.userId)) {
        this.ON_SHOW_TOAST({
          message: this.$t("stated.addUserStated"),
          styleType: "danger",
        });
      } else {
        let filter = {
          eventId: this.eventId,
          status: SentimentStatus.WAITING,
          description: this.addUserStated.description,
          userId: this.addUserStated.userId
        };
        this.CHAIRMAN_SECRETARY_ADD_USER_STATED(filter)
          .then(
            function() {
              this.refreshData();
              this.$refs['addUserStatedModal'].hide()
            }.bind(this)
          )
          .catch(
            function(error) {
              if (error.response.data.errorText !== undefined){
                this.ON_SHOW_TOAST({
                  message: this.$t(error.response.data.errorText),
                  styleType: "danger",
                });
              }else{
                this.ON_SHOW_TOAST({
                  message: this.$t("common.somethingWentWrong"),
                  styleType: "danger",
                });
              }
            }.bind(this)
          );
      }
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssignerSelect: function (data) {
      this.addUserStated.userId = data !== null ? data.id : null
    },
    refreshData: function () {
      let filter = {
        params: {
          eventId: this.eventId,
          isGetAll: true
        },
      };
      this.isLoading = true
      this.GET_SENTIMENT_IN_EVENT(filter).then(
        function () {
          // Todo
          this.getLastActiveVoice()
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    checkUserSpeakingOrCrimticism: function (currentSentiment) {
      let result = false
      if (currentSentiment.status === this.sentimentStatus.DOING || currentSentiment.status === this.sentimentStatus.CRITICIZE) {
        result = true
      } else {
        let index = this.sentimentList.findIndex(
          x=> (x.status === this.sentimentStatus.DOING || currentSentiment.status === this.sentimentStatus.CRITICIZE))
        result = index !== -1 ? true : false
      }
      return result
    },
    getLastActiveVoice: function() {
      let filter = {
        params: {
          eventId: sessionStorage.getItem("event_id")
        }
      }
      this.GET_LAST_ACTIVE_VOICE(filter)
    },
    createActiveVoice: function() {
      let data = {
        eventId: sessionStorage.getItem("event_id"),
        userId: this.sentimentSelected.user_id,
        sentimentId: this.sentimentSelected.id,
        criticizeSentimentId: null,
        startTime: moment(),
        expectedEndTime: moment().add(this.activeVoiceConfig, 'seconds')
      }
      this.CREATE_ACTIVE_VOICE(data)
    },
    updateActiveVoice: function() {
      let data = {
        id: this.GET_LAST_ACTIVE_VOICE_DATA.id,
        expectedEndTime: moment(),
        sentimentId: this.GET_LAST_ACTIVE_VOICE_DATA.sentiment
      }
      this.UPDATE_ACTIVE_VOICE(data)
    },
    getEventDetail: function() {
      let event = {
        params:{
          is_meeting: true
        },
        id: sessionStorage.getItem("event_id")
      }
      this.GET_EVENT_DETAIL(event);
    },
    checkShowVoice: function() {
      let data = this.GET_LAST_ACTIVE_VOICE_DATA
      if (Object.keys(data).length === 0) {
        return false
      } else {
        let expectedTime = !functionUtils.validateStringIsNull(data.expected_end_time)
          ? moment(data.expected_end_time) : null
        this.endTimeActiveVoice = !functionUtils.validateStringIsNull(expectedTime) 
          ? expectedTime.format("YYYY-MM-DD HH:mm:ss") : null
        if (moment() < expectedTime) return true
      }
      return false
    },
    updateSentimentAndCriticizeSentiment: function() {
      let data = this.GET_LAST_ACTIVE_VOICE_DATA
      if (data.sentiment) {
        this.autoUpdateSentimentStatusCriticize(data.sentiment)
      }
      if (data.criticize_sentiment) {
        this.$refs.criticismModal.autoUpdateCriticizeStatus(data.criticize_sentiment)
      }
    },
    autoUpdateSentimentStatusCriticize: async function(sentimentId) {
      let index = this.sentimentList.findIndex(x=>x.id=sentimentId)
      if (index !== -1) {
        if(this.sentimentList[index].status === this.sentimentStatus.DOING) {
          this.sentimentItemIndex = index;
          await this.setDefaultSentiment(this.sentimentList[index])
          this.sentimentManipulation(SentimentActionType.CRITICIZE)
        }
      }
    },
    ...mapActions([
      "GET_SENTIMENT_IN_EVENT",
      "UPDATE_PRIORITY_SENTIMENT_IN_EVENT",
      "UPDATE_SENTIMENT",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "GET_USER_IN_EVENT_LIST",
      "ON_SHOW_TOAST",
      "CHAIRMAN_SECRETARY_ADD_USER_STATED",
      "GET_LAST_ACTIVE_VOICE",
      "CREATE_ACTIVE_VOICE",
      "GET_EVENT_DETAIL",
      "UPDATE_ACTIVE_VOICE"
    ]),
  },
  created() {
    this.addUserStated.description = this.$t('sideBar.stated')
    this.eventId = sessionStorage.getItem("event_id");
    // auto update sentiment when user load page
    let filter = {
      params: {
        eventId: sessionStorage.getItem("event_id")
      }
    }
    this.GET_LAST_ACTIVE_VOICE(filter)
      .then(
        function (res) {
          // Todo
          this.isLoading = false
          let endTimeActiveVoice = !functionUtils.validateStringIsNull(res.expected_end_time)
            ? moment(res.expected_end_time) : null
          if (moment() > endTimeActiveVoice && res.sentiment) {
            this.autoUpdateSentimentStatusCriticize(res.sentiment)
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )

    this.getEventDetail()
    // let filter = {
    //   params: {
    //     eventId: this.eventId,
    //     isGetAll: true
    //   },
    // };
    // this.isLoading = true
    // this.GET_SENTIMENT_IN_EVENT(filter).then(
    //   function () {
    //     // Todo
    //   }.bind(this)
    // ).catch(
    //   function () {
    //     this.isLoading = false
    //   }.bind(this)
    // )
    this.refreshData()
    // this.webSocketSentiment()
    let event_filter = {
      params: {
        event: this.eventId,
        getAllUserInGroup: true,
        // bandGroup: true
      }
    };
    this.GET_USER_IN_EVENT_LIST(event_filter);
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.ghost {
  opacity: 0.5;
  background: #eceff0;
}
.list-group-item {
  padding: 0;
  border: none !important;
}
.btn-reject-end {
  padding-bottom: 2px;
  border: 1px solid #f2404d;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn-allow-speak {
  padding-bottom: 2px;
  color: #26BA50;
  border: 1px solid #26ba50;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  cursor: pointer;
}
.btn-criticize{
  padding-bottom: 2px;
  border: 1px solid #006ad4;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.mt-35 {
  margin-top: 35px;
}
.sentiment-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
}
.fixed {
  background-color: rgba(236, 239, 240, 0.17);
}
.user-stated {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.btn-allow-speak-disable {
  color: #7d7d7d;
  padding-bottom: 2px;
  border: 1px solid #7d7d7d;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}
</style>
