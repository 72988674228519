<template>
  <div>
    <div
      class="container-fluid"
      style="background: white; border-radius: 5px; border: 1px solid #7D7D7D;"
    >
      <div class="row p-2">
        <div class="col-lg-12 col-md-12 col-sm-12 p-0">
          <div
            class="pb-2"
            style="display: flex; justify-content: space-between; margin-top: 30px; border-bottom: 1px solid #CCCCCC;"
          >
            <div class="text-left">
              {{ $t("stated.criticismTitle") }}
            </div>
            <div v-if="!isManager && userSpeakingId != GET_CURRENT_ACCOUNT.id">
              <button
                v-if="sentimentSpeakingStatus === sentimentStatus.DOING || sentimentSpeakingStatus === sentimentStatus.CRITICIZE"
                type="submit"
                class="btn btn-outline-primary meeting-font-regular float-right ml-2"
                style="font-size: small;"
                @click="onShowModalCriticism"
              >
                {{ $t("stated.btnCriticism") }}
              </button>
              <button
                v-if="sentimentSpeakingStatus === sentimentStatus.DOING || sentimentSpeakingStatus === sentimentStatus.CRITICIZE"
                type="submit"
                class="btn btn-outline-danger meeting-font-regular float-right"
                style="font-size: small;"
                :disabled="sentimentForCurrentUser.id == null"
                @click="cancelCriticism"
              >
                {{ $t("stated.btnCancelCriticism") }}
              </button>
            </div>
          </div>
          <div class="mt-2" style="overflow: auto; height: 50vh">
            <draggable
              v-if="isManager"
              v-model="criticismList"
              class="list"
              handle=".draggable"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="onEndDragging()"
            >
              <div
                :class="{ draggable: !element.fixed, fixed: element.fixed }"
                v-for="(element, index) in criticismList"
                :key="element.name"
                style="border-bottom: 1px solid #CCCCCC;"
              >
                <div
                  class="sentiment-item"
                  :style="
                    criticismItemIndex == index
                      ? 'background-color: #ECEFF0; cursor: pointer;'
                      : 'cursor: pointer;'
                  "
                  @click="onSelectSentiment(element, index)"
                >
                  <!-- Stt -->
                  <div class="meeting-font-regular" style="width: 5%;">
                    <span class="meeting-font-medium">{{ index + 1 }}</span>
                  </div>
                  <!-- Fullname and position -->
                  <div class="text-left" style="width: 65%;">
                    <span
                      class="meeting-font-medium meeting-font-regular"
                      style="color: #000000;"
                      >{{ element.fullname }}</span
                    >
                    <span
                      class="meeting-font-medium"
                      style="display: block; color: #707070;"
                      >{{
                        getCompanyAndPosition(
                          element.companyName,
                          element.companyPosition
                        )
                      }}
                      - {{ $t("stated.seat") }}: {{ element.seatCode }}</span
                    >
                  </div>
                  <!-- Status -->
                  <div
                    class="meeting-font-regular"
                    style="width: 20%; align-self: center; text-align: center;"
                  >
                    <span
                      class="meeting-font-small"
                      v-if="element.status == sentimentStatus.DONE"
                      style="color: #26BA50"
                    >
                      {{ $t(getStatus(element.status)) }}
                    </span>
                    <span
                      class="meeting-font-small"
                      v-else-if="element.status == sentimentStatus.DOING"
                      style="color: #00bcd4"
                    >
                      {{ $t(getStatus(element.status)) }}
                    </span>
                    <span
                      class="meeting-font-small"
                      v-else
                      style="color: #F2404D"
                    >
                      {{ $t(getStatus(element.status)) }}
                    </span>
                  </div>
                  <!-- Image -->
                  <div style="align-self: center; width: 5%;">
                    <img src="../../assets/icons/4_dots.svg" />
                  </div>
                </div>
                <!--  -->
                <div v-if="sentimentSelected.id===element.id">
                  <div class="text-left" style="padding: 15px 52px 15px 52px;" v-if="criticismList.length!=0">
                    <!-- user speak -->
                    <div class="col-md-12 pb-3 pl-0">
                      <div class="row">
                        <div class="col-sm-4">
                          <span class="meeting-font-large" style="color: #7D7D7D;">
                            {{ $t('stated.speaker') }}
                          </span>
                        </div>
                        <div class="col-sm-8">
                          <span
                            class="meeting-font-large meeting-font-regular pre-text"
                            style="display: block"
                          >{{element.fullname}}</span>
                          <div>
                            <p class="m-0 meeting-font-medium">{{$t('register.position')}}: {{element.companyPosition}}</p>
                            <p class="m-0 meeting-font-medium">{{$t('register.companyName')}}: {{element.companyName}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Content speak -->
                    <div class="col-md-12 pb-3 pl-0">
                      <div class="row">
                        <div class="col-sm-4">
                          <span class="meeting-font-large" style="color: #7D7D7D;">
                            {{ $t('stated.contentSpeak') }}
                          </span>
                        </div>
                        <div class="col-sm-8">
                          <span
                            class="meeting-font-large meeting-font-regular pre-text"
                            style="display: block"
                          >{{element.description}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- Time speak -->
                    <div class="col-md-12 pl-0">
                      <div class="row">
                        <div class="col-sm-4">
                          <span class="meeting-font-large" style="color: #7D7D7D;">
                            {{ $t('support.time') }}
                          </span>
                        </div>
                        <div class="col-sm-8">
                          <span
                            class="meeting-font-large meeting-font-regular pre-text"
                            style="display: block"
                          >
                            <!-- {{element.timeSpeak}}
                              {{ element.timeSpeak ? 
                              (element.timeSpeak.hours + ` ${ element.timeSpeak.hours > 1 ? $t('stated.hours') : $t('stated.hour') }, ` 
                              + element.timeSpeak.minutes + ` ${ element.timeSpeak.minutes > 1 ? $t('stated.minutes') : $t('stated.minute') } `) : '' }} -->
                            {{element.timeSpeak 
                              ? (element.timeSpeak.hours > 1
                                  ? `${ element.timeSpeak.hours} ${$t('stated.hours')} , `: '')
                                + (element.timeSpeak.minutes > 1
                                  ? `${ element.timeSpeak.minutes}  ${$t('stated.minutes')} , `: '')
                                + (element.timeSpeak.seconds > 1
                                  ? `${ element.timeSpeak.seconds}  ${$t('stated.seconds')} `: '')
                              : null}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mt-35" v-if="sentimentSpeakingStatus === sentimentStatus.CRITICIZE">
                      <!-- Allow speak -->
                      <div
                        class="btn-allow-speak"
                        v-if="element.status === sentimentStatus.WAITING"
                        :class="`${checkUserCriticism(element) ? 'btn-allow-speak-disable' : 'btn-allow-speak'}`"
                        :disabled="checkUserCriticism(element)"
                        @click="checkUserCriticism(element) ? '' : updateCriticismStatus(element, sentimentActionType.ALLOW)"
                      >
                        <span class="meeting-font-small meeting-font-regular"
                          >{{ $t('stated.speechPermission') }}</span
                        >
                      </div>
                      <!-- Reject speak -->
                      <div
                        class="btn-reject-end"
                        style="margin-right: 5px; cursor: pointer;"
                        v-if="element.status === sentimentStatus.WAITING"
                        @click="updateCriticismStatus(element, sentimentActionType.NOT_ALLOW)"
                      >
                        <span class="meeting-font-small meeting-font-regular" style="color: #F2404D;"
                          >{{ $t('stated.speechNotAllowed') }}</span
                        >
                      </div>
                      <!-- End speak -->
                      <div
                        class="btn-reject-end"
                        style="margin-right: 5px; cursor: pointer;"
                        v-if="element.status === sentimentStatus.DOING"
                        @click="updateCriticismStatus(element, sentimentActionType.END)"
                      >
                        <span class="meeting-font-small meeting-font-regular" style="color: #F2404D;"
                          >{{ $t('stated.close') }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
            <div
              v-else
              :class="{ draggable: !element.fixed, fixed: element.fixed }"
              v-for="(element, index) in criticismList"
              :key="element.name"
              style="border-bottom: 1px solid #CCCCCC;"
            >
              <div
                class="sentiment-item"
                :style="
                  criticismItemIndex == index
                    ? 'background-color: #ECEFF0; cursor: pointer;'
                    : 'cursor: pointer;'
                "
                @click="onSelectSentiment(element, index)"
              >
                <!-- Stt -->
                <div class="meeting-font-regular" style="width: 5%;">
                  <span class="meeting-font-medium">{{ index + 1 }}</span>
                </div>
                <!-- Fullname and position -->
                <div class="text-left" style="width: 65%;">
                  <span
                    class="meeting-font-medium meeting-font-regular"
                    style="color: #000000;"
                    >{{ element.fullname }}</span
                  >
                  <span
                    class="meeting-font-medium"
                    style="display: block; color: #707070;"
                    >{{
                      getCompanyAndPosition(
                        element.companyName,
                        element.companyPosition
                      )
                    }}
                    - {{ $t("stated.seat") }}: {{ element.seatCode }}</span
                  >
                </div>
                <!-- Status -->
                <div
                  class="meeting-font-regular"
                  style="width: 20%; align-self: center; text-align: center;"
                >
                  <span
                    class="meeting-font-small"
                    v-if="element.status == sentimentStatus.DONE"
                    style="color: #26BA50"
                  >
                    {{ $t(getStatus(element.status)) }}
                  </span>
                  <span
                    class="meeting-font-small"
                    v-else
                    style="color: #F2404D"
                  >
                    {{ $t(getStatus(element.status)) }}
                  </span>
                </div>
                <!-- Image -->
                <div style="align-self: center; width: 5%;">
                  <img src="../../assets/icons/4_dots.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!--  -->
    <criticizeSentimentModal
      ref="criticizeSentimentModal"
      @getApiCriticismList="getApiCriticismList"
    />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import criticizeSentimentModal from "./CriticizeSentimentModal";
import SentimentActionType from 'enum/sentimentActionType'
// enum
import SentimentStatus from "enum/sentimentStatus";
// utils
import functionUtils from "utils/functionUtils";
import dateUtils from "utils/dateUtils";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
export default {
  components: {
    draggable,
    criticizeSentimentModal,
  },
  props: {
    sentimentId: {
      type: Number,
      default() {
        return null;
      },
    },
    sentimentSpeakingStatus: {
      type: Number,
      default() {
        return null;
      },
    },
    isManager: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userSpeakingId: {
      type: Number,
      default() {
        return null;
      },
    }
  },
  data: () => ({
    dragging: false,
    fieldBlured: false,
    criticismList: [],
    description: null,
    eventId: null,
    criticismItemIndex: 0,
    sentimentForCurrentUser: {
      id: null,
      status: null,
      dateCreated: null,
      description: null,
      timeSpeak: null,
    },
    isEdit: false,
    isCancel: false,
    sentimentSelected: {
      id: 0,
      status: null,
      dateCreated: "",
      description: "",
      timeSpeak: null,
      user_id: null,
    },
    sentimentStatus: {
      DONE: SentimentStatus.DONE,
      WAITING: SentimentStatus.WAITING,
      CANCELLED: SentimentStatus.CANCELLED,
      DOING: SentimentStatus.DOING,
      ABORTED: SentimentStatus.ABORTED,
      CRITICIZE: SentimentStatus.CRITICIZE
    },
    sentimentActionType: {
      ALLOW: SentimentActionType.ALLOW,
      NOT_ALLOW: SentimentActionType.NOT_ALLOW,
      END: SentimentActionType.END,
      CRITICIZE: SentimentActionType.CRITICIZE
    },
    activeVoiceConfig: 0
  }),
  computed: {
    ...mapGetters([
      "GET_CRITICISM_IN_SENTIMENT_DATA",
      "GET_CURRENT_ACCOUNT",
      "EVENT_DETAIL_DATA",
      "GET_LAST_ACTIVE_VOICE_DATA"
    ]),
  },
  watch: {
    GET_CRITICISM_IN_SENTIMENT_DATA: function() {
      this.criticismList = [];
      this.GET_CRITICISM_IN_SENTIMENT_DATA &&
        this.GET_CRITICISM_IN_SENTIMENT_DATA.forEach((element, index) => {
          let object = {
            fixed: this.getFixed(element.status),
            id: element.id,
            stt: index + 1,
            email: element.user_created.email,
            fullname:
              element.user_created.last_name +
              " " +
              element.user_created.first_name,
            companyName: element.company.name,
            companyPosition: element.company.position,
            status: element.status,
            selected: false,
            description: element.content,
            dateCreated: element.date_created,
            timeSpeak: this.getTimeSpeak(element.start_time, element.end_time),
            seatCode: element.seat_code,
            user_id: element.user_created.id,
          };
          this.criticismList.push(object);
        });
      this.isLoading = false;
      // Set default
      this.setDefaultSentiment(this.criticismList[this.criticismItemIndex]);
      this.getCriticismForCurrentUser();
      // auto update criticize when user load page
      let filter = {
        params: {
          eventId: sessionStorage.getItem("event_id")
        }
      }
      this.GET_LAST_ACTIVE_VOICE(filter)
        .then(
          function (res) {
            // Todo
            this.isLoading = false
            let endTimeActiveVoice = !functionUtils.validateStringIsNull(res.expected_end_time)
              ? moment(res.expected_end_time) : null
            if (moment() > endTimeActiveVoice && res.criticize_sentiment) {
              this.autoUpdateCriticizeStatus(res.criticize_sentiment)
            }
          }.bind(this)
        ).catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    sentimentId: function() {
      this.getCriticismList(this.sentimentId);
    },
    EVENT_DETAIL_DATA: function() {
      let data = this.EVENT_DETAIL_DATA
      this.activeVoiceConfig = data.active_voice_time_config
    }
  },
  created() {
    this.getCriticismList(this.sentimentId);
    this.getEventDetail()
  },
  methods: {
    onShowModal: function() {
      this.$refs["criticismModal"].show();
    },
    /**
     * Check move
     */
    checkMove: function(e) {
      return e.draggedContext;
    },
    /**
     * Check null
     */
    isNull: function(data) {
      return functionUtils.isNull(data);
    },
    /**
     * Get time speak
     */
    getTimeSpeak: function(startTime, endTime) {
      if (functionUtils.isNull(startTime) || functionUtils.isNull(endTime)) {
        return null;
      } else {
        let start = new Date(
          dateUtils.formatDate(
            startTime,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        let end = new Date(
          dateUtils.formatDate(
            endTime,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        var timeRemaining = end.getTime() - start.getTime();
        return dateUtils.millisToHoursMinutesAndSeconds(timeRemaining);
      }
    },
    /**
     * Get fixed
     */
    getFixed: function(status) {
      if (status == SentimentStatus.WAITING) {
        return false;
      }
      return true;
    },
    /**
     * Set default sentiment
     */
    setDefaultSentiment: function(sentiment) {
      this.sentimentSelected.description = sentiment && sentiment.description;
      this.sentimentSelected.id = sentiment && sentiment.id;
      this.sentimentSelected.dateCreated = sentiment
        ? dateUtils.formatDate(
            sentiment.dateCreated
              ? sentiment.dateCreated
              : sentiment.date_created,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            dateUtils.STATIC_FORMAT_TIME_DATE
          )
        : null;
      this.sentimentSelected.status = sentiment && sentiment.status;
      this.sentimentSelected.timeSpeak = sentiment && sentiment.timeSpeak;
      this.sentimentSelected.user_id = sentiment && sentiment.user_id;
    },
    /**
     * Select sentiment
     */
    onSelectSentiment: function(element, index) {
      this.criticismItemIndex = index;
      this.setDefaultSentiment(element);
    },
    /**
     * check company name and company position
     */
    getCompanyAndPosition: function(company, position) {
      return functionUtils.getCompanyAndPosition(company, position);
    },
    /**
     * Cancel sentiment
     */
    onCancelSentiment: function() {
      if (
        !functionUtils.isNull(this.sentimentForCurrentUser.id) &&
        this.sentimentForCurrentUser.status == SentimentStatus.WAITING
      ) {
        this.isEdit = false;
        this.isCancel = true;
        this.description = this.sentimentForCurrentUser.description;
      }
    },
    /**
     * Get status
     */
    getStatus: function(status) {
      if (status == SentimentStatus.DONE) {
        return "stated.statusType.done";
      } else if (status == SentimentStatus.WAITING) {
        return "stated.statusType.waiting";
      } else if (status == SentimentStatus.CANCELLED) {
        return "stated.statusType.cancelled";
      } else if (status == SentimentStatus.DOING) {
        return "stated.statusType.doing";
      } else if (status == SentimentStatus.ABORTED) {
        return "stated.statusType.aborted";
      } else {
        return "";
      }
    },
    getCriticismList: function(sentimentId) {
      let eventId = sessionStorage.getItem("event_id");
      let filter = {
        params: {
          sentimentId: sentimentId,
          event_id: eventId
        },
      };
      this.GET_CRITICISM_IN_SENTIMENT(filter)
        .then(
          function() {
            // Todo
            this.getLastActiveVoice()
          }.bind(this)
        )
        .catch(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    },
    getCriticismForCurrentUser: function() {
      let criticismList = [...this.criticismList];
      let index = criticismList.findIndex(
        (x) =>
          x.user_id === this.GET_CURRENT_ACCOUNT.id &&
          x.status === this.sentimentStatus.WAITING
      );
      if (index != -1) {
        this.sentimentForCurrentUser.id = criticismList[index].id;
        this.sentimentForCurrentUser.status = criticismList[index].status;
        this.sentimentForCurrentUser.dateCreated =
          criticismList[index].dateCreated;
        this.sentimentForCurrentUser.timeSpeak = criticismList[index].timeSpeak;
        this.sentimentForCurrentUser.description =
          criticismList[index].description;
      } else {
        this.sentimentForCurrentUser = {
          id: null,
          status: null,
          dateCreated: null,
          description: null,
          timeSpeak: null,
        };
      }
    },
    // add register criticism
    onShowModalCriticism: function() {
      this.$refs.criticizeSentimentModal.onShowModal(
        this.sentimentId,
        this.sentimentForCurrentUser
      );
    },
    // cancel criticism
    cancelCriticism: function() {
      this.$refs.criticizeSentimentModal.onCancelRegister(
        this.sentimentForCurrentUser
      );
    },
    getApiCriticismList: function() {
      this.getCriticismList(this.sentimentId);
    },
    /**
     * End dragging
     */
    onEndDragging: function() {
      this.dragging = false;
      let filter = {
        criticismList: this.criticismList,
      };
      this.UPDATE_PRIORITY_SENTIMENT_IN_EVENT(filter);
    },
    isDraggable(context) {
      const { index, futureIndex } = context;
      return !(
        this.criticismList[index].fixed || this.criticismList[futureIndex].fixed
      );
    },
    checkUserCriticism: function(userCriticism) {
      let result = false
      if (userCriticism.status === this.sentimentStatus.DOING) {
        result = true
      } else {
        let index = this.criticismList.findIndex(x=>x.status === this.sentimentStatus.DOING)
        result = index !== -1 ? true : false
      }
      return result
    },
    updateCriticismStatus: function (criticismData, statusValue) {
      let data = {
        id: criticismData.id,
        actionType: statusValue,
        isAdminRole: true
      }
      if (statusValue === this.sentimentActionType.ALLOW) {
        this.createActiveVoice()
      }
      if (statusValue === this.sentimentActionType.END) {
        this.updateActiveVoice()
      }
      this.UPDATE_CRITICISM(data)
        .then(
          function() {
            this.getCriticismList(this.sentimentId);
          }.bind(this)
        )
        .catch(
          function() {
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    getEventDetail: function() {
      let event = {
        params:{
          is_meeting: true
        },
        id: sessionStorage.getItem("event_id")
      }
      this.GET_EVENT_DETAIL(event);
    },
    // active voice
    createActiveVoice: function() {
      let data = {
        eventId: sessionStorage.getItem("event_id"),
        userId: this.sentimentSelected.user_id,
        sentimentId: null,
        criticizeSentimentId: this.sentimentSelected.id,
        startTime: moment(),
        expectedEndTime: moment().add(this.activeVoiceConfig, 'seconds')
      }
      this.CREATE_ACTIVE_VOICE(data)
    },
    updateActiveVoice: function() {
      let data = {
        id: this.GET_LAST_ACTIVE_VOICE_DATA.id,
        expectedEndTime: moment()
      }
      this.UPDATE_ACTIVE_VOICE(data)
    },
    getLastActiveVoice: function() {
      let filter = {
        params: {
          eventId: sessionStorage.getItem("event_id")
        }
      }
      this.GET_LAST_ACTIVE_VOICE(filter)
    },
    autoUpdateCriticizeStatus: async function(criticizeId) {
      let index = this.criticismList.findIndex(x=>x.id === criticizeId)
      if (index !== -1) {
        if (this.criticismList[index].status === this.sentimentStatus.DOING) {
          this.criticismItemIndex = index;
          await this.setDefaultSentiment(this.criticismList[index]);
          this.updateCriticismStatus(this.criticismList[index], this.sentimentActionType.END)
        }
      }
    },
    ...mapActions([
      "GET_CRITICISM_IN_SENTIMENT",
      "GET_SENTIMENT_WITH_CURRENT_USER",
      "UPDATE_CRITICISM",
      "CREATE_ACTIVE_VOICE",
      "GET_LAST_ACTIVE_VOICE",
      "GET_EVENT_DETAIL",
      "UPDATE_ACTIVE_VOICE"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button-grey {
  background: #bdbdbd;
  color: #ffffff;
}
.submit-button-primary {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
  color: #fff;
}
//
.ghost {
  opacity: 0.5;
  background: #eceff0;
}
.list-group-item {
  padding: 0;
  border: none !important;
}
.btn-reject-end {
  padding-bottom: 2px;
  border: 1px solid #f2404d;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn-allow-speak {
  color: #26BA50;
  padding-bottom: 2px;
  border: 1px solid #26ba50;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  cursor: pointer;
}
.btn-allow-speak-disable {
  color: #7d7d7d;
  padding-bottom: 2px;
  border: 1px solid #7d7d7d;
  border-radius: 5px;
  width: 30%;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}
.mt-35 {
  margin-top: 35px;
}
.sentiment-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
}
.fixed {
  background-color: rgba(236, 239, 240, 0.17);
}
.sentiment-select {
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 10px 15px;
}
.criticize-sentiment::-webkit-scrollbar {
  display: none;
}

</style>
