<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 p-0" style="padding: 20px !important;">
      <!-- Content register stated -->
      <div class="meeting-font-regular" style="text-align: left;">
        <span style="font-size: 16px;">{{
          $t("stated.enterContentRegisterSpeak")
        }}</span>
      </div>
      <!-- Description -->
      <div class="mt-3">
        <textarea
          v-model="description"
          class="form-control meeting-font-regular"
          style="font-size: 16px;"
          :placeholder="$t('stated.enterContentSpeak')"
          rows="3"
          v-bind:class="{
            'form-control': true,
            'is-invalid': !validRequire(description) && fieldBlured,
          }"
          v-on:blur="fieldBlured = true"
        ></textarea>
        <div class="invalid-feedback" style="text-align: left;">
          {{ $t("stated.requireContentSpeak") }}
        </div>
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 30px;"
      >
        <div
          style="display: flex; align-self: center; cursor: pointer;"
          @click="onEditSentiment()"
        >
          <img
            v-if="
              !isNull(sentimentForCurrentUser.status) &&
                sentimentForCurrentUser.status == sentimentStatus.WAITING
            "
            class="mr-1"
            src="../../assets/icons/edit.svg"
          />
          <img v-else class="mr-1" src="../../assets/icons/edit-grey.svg" />
          <span class="meeting-font-regular" style="align-self: center;">{{
            $t("stated.editSpeak")
          }}</span>
        </div>
        <div
          @click="onCancelSentiment()"
          style="display: flex; align-self: center; cursor: pointer;"
        >
          <img
            v-if="
              !isNull(sentimentForCurrentUser.status) &&
                sentimentForCurrentUser.status == sentimentStatus.WAITING
            "
            class="mr-1"
            src="../../assets/icons/close-red.svg"
          />
          <img v-else class="mr-1" src="../../assets/icons/close-grey.svg" />
          <span class="meeting-font-regular">{{
            $t("stated.cancelRegister")
          }}</span>
        </div>
        <div style="align-self: center;">
          <button
            @click="checkForm()"
            type="submit"
            :class="
              !isNull(sentimentForCurrentUser.status) &&
              !isEdit &&
              !isCancel &&
              sentimentForCurrentUser.status == sentimentStatus.WAITING
                ? 'btn submit-button-grey meeting-font-regular'
                : 'btn submit-button-primary meeting-font-regular'
            "
            style="font-size: small;"
          >
            {{
              isEdit
                ? $t("stated.update")
                : isCancel
                ? $t("stated.cancel")
                : $t("stated.sendRegister")
            }}
          </button>
        </div>
      </div>
      <div class="mt-3">
        <!-- <div style="align-self: left;">
          <button
            @click="showModalCriticism()"
            type="submit"
            class="btn submit-button-primary meeting-font-regular"
            style="font-size: small;"
          >
            {{ $t("stated.btnCriticism") }}
          </button>
        </div> -->
        <div style="padding: 20px !important; background-color: #ECEFF0;">
          <div
            class="mt-3"
            style="background-color: #fff; border-radius: 5px; border: 1px solid #7D7D7D;"
          >
            <div class="row p-2">
              <div class="col-sm-4 text-left" style="align-self: center;">
                <span style="font-size: 14px; color: #707070;">{{
                  $t("stated.status")
                }}</span>
              </div>
              <div class="col-sm-8 text-left meeting-font-regular">
                <span
                  v-if="isNull(sentimentForCurrentUser.status)"
                  style="color: #F2404D; font-size: 14px;"
                  >{{ $t(getSentimentForCurrentUserStatus()) }}</span
                >
                <span
                  v-else-if="
                    sentimentForCurrentUser.status != sentimentStatus.DONE
                  "
                  style="color: #F2404D; font-size: 14px;"
                >
                  {{
                    $t(
                      getSentimentForCurrentUserStatus(
                        sentimentForCurrentUser.status
                      )
                    )
                  }}
                </span>
                <span v-else style="color: #26BA50;">{{
                  $t(
                    getSentimentForCurrentUserStatus(
                      sentimentForCurrentUser.status
                    )
                  )
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="mt-3"
            style="background-color: #fff; border-radius: 5px; border: 1px solid #7D7D7D;"
          >
            <div class="row p-2">
              <div class="col-sm-4 text-left" style="align-self: center;">
                <span style="font-size: 14px; color: #707070;">{{
                  $t("stated.timeSpeak")
                }}</span>
              </div>
              <div class="col-sm-8 text-left meeting-font-regular">
                <span style="font-size: 14px;">{{
                  sentimentForCurrentUser.timeSpeak
                    ? sentimentForCurrentUser.timeSpeak.hours +
                      ` ${
                        sentimentForCurrentUser.timeSpeak.hours > 1
                          ? $t("stated.hours")
                          : $t("stated.hour")
                      }, ` +
                      sentimentForCurrentUser.timeSpeak.minutes +
                      ` ${
                        sentimentForCurrentUser.timeSpeak.minutes > 1
                          ? $t("stated.minutes")
                          : $t("stated.minute")
                      } `
                    : ""
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="mt-3"
            style="background-color: #fff; border-radius: 5px; border: 1px solid #7D7D7D;"
          >
            <div class="row p-2">
              <div class="col-sm-4 text-left" style="align-self: start;">
                <span style="font-size: 14px; color: #707070;">{{
                  $t("stated.noSpeak")
                }}</span>
              </div>
              <div class="col-sm-8 text-left">
                <div
                  v-for="(stated, index) in sentimentList"
                  :key="stated + index"
                >
                  <div
                    style="display: flex; justify-content: space-between; font-size: 14px;"
                  >
                    <div style="width: 10%;">
                      <span class="meeting-font-regular">{{ stated.stt }}</span>
                    </div>
                    <div
                      style="text-align: left; width: 60%; overflow-wrap: break-word;"
                    >
                      <span class="meeting-font-regular">{{
                        stated.fullname + " (" + stated.email + ")"
                      }}</span>
                      <span style="display: block; color: #707070;">{{
                        stated.companyName + " - " + stated.companyPosition
                      }}</span>
                      <span
                        class="meeting-font-medium"
                        style="display: block; color: #707070;"
                        >{{ $t("stated.seat") }}: {{ stated.seatCode }}</span
                      >
                    </div>
                    <div style="text-align: left; width: 30%;">
                      <span v-if="stated.status == 1" style="color: #26BA50">
                        {{ $t(getStatus(stated.status)) }}
                      </span>
                      <span v-else style="color: #F2404D">
                        {{ $t(getStatus(stated.status)) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-sm-12 col-md-6 p-0"
      style="padding: 20px !important; background-color: #ECEFF0; height: 100vh;"
    >
      <!-- time countdown -->
      <div 
        v-if="checkShowVoice()"
        style="padding: 5px; display: flex">
        <div class="text-left col-md-6">{{ $t('stated.speaker') }}: {{fullNameUserVoice}}</div>
        <div class="col-md-6">
          <flip-count-down 
            :endTime="endTimeActiveVoice"
            @timeElapsedHandler="callBackEndTimeCountDown"
          ></flip-count-down>
        </div>
      </div>
      <!-- end time countdown -->
      <div
        class="mt-3"
        style="background-color: #fff; border-radius: 5px; border: 1px solid #7D7D7D;"
      >
        <div class="row p-2" v-if="sentimentUserSpeaking.id !== null">
          <div class="col-sm-12 text-left">
            <div>
              <div
                style="display: flex; justify-content: space-between; font-size: 14px;"
              >
                <div
                  style="text-align: left; width: 60%; overflow-wrap: break-word;"
                >
                  <span class="meeting-font-regular">{{
                    sentimentUserSpeaking.userCreatedName + " (" + sentimentUserSpeaking.userCreatedEmail + ")"
                  }}</span>
                  <span style="display: block; color: #707070;">{{
                    !validateStringIsNull(sentimentUserSpeaking.companyName)
                    && !validateStringIsNull(sentimentUserSpeaking.companyPosition)
                    ? (sentimentUserSpeaking.companyName + " - " + sentimentUserSpeaking.companyPosition)
                    : (validateStringIsNull(sentimentUserSpeaking.companyPosition)
                    ? sentimentUserSpeaking.companyName: '')
                  }}</span>
                  <span
                    class="meeting-font-medium"
                    style="display: block; color: #707070;"
                    >{{ $t("stated.seat") }}: {{ sentimentUserSpeaking.seatCode }}</span
                  >
                  <span
                    class="meeting-font-medium"
                    style="display: block; color: #707070;"
                    >{{ $t("support.time") }}: {{ sentimentUserSpeaking.startTime }}</span
                  >
                </div>
                <div style="text-align: left; width: 30%;">
                  <span v-if="sentimentUserSpeaking.status == sentimentStatus.DOING" style="color: #006ad4">
                    {{ $t(getStatus(sentimentUserSpeaking.status)) }}
                  </span>
                  <span v-else style="color: #F2404D">
                    {{ $t(getStatus(sentimentUserSpeaking.status)) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-left meeting-font-medium">
            <div class="form-group">
              <label for="">{{$t('stated.contentSpeak')}}:</label>
              <p class="font-weight-bold">{{sentimentUserSpeaking.description}}</p>
            </div>
          </div>
        </div>
        <div v-else class="row p-2">
          <div class="col-sm-12 text-center" style="color: #006ad4">
            {{$t('stated.notFoundUserStated')}}
          </div>
        </div>
      </div>
      <!--  -->
      <criticizeSentimentList
        :sentimentId="sentimentUserSpeaking.id"
        :sentimentSpeakingStatus="sentimentUserSpeaking.status"
        :userSpeakingId="sentimentUserSpeaking.userCreatedId"
        ref="criticismModal"
        style="margin-top: 15px" />
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import SentimentStatus from "enum/sentimentStatus";
import dateUtils from "utils/dateUtils";
import { mapActions, mapGetters } from "vuex";
import functionUtils from "utils/functionUtils";
import Enum from "enum/enum";
import criticizeSentimentList from "./CriticizeSentimentList";
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
import moment from 'moment';
import FlipCountDown from 'Components/FlipCountDown'

export default {
  components: {
    criticizeSentimentList,
    FlipCountDown
  },
  data: () => ({
    isLoading: false,
    fieldBlured: false,
    description: "",
    sentimentList: [],
    sentiment: {
      status: "",
      dateCreated: null,
    },
    sentimentForCurrentUser: {
      id: null,
      status: null,
      dateCreated: null,
      description: null,
      timeSpeak: null,
    },
    isEdit: false,
    isCancel: false,
    sentimentStatus: {
      DONE: SentimentStatus.DONE,
      WAITING: SentimentStatus.WAITING,
      CANCELLED: SentimentStatus.CANCELLED,
      DOING: SentimentStatus.DOING,
      ABORTED: SentimentStatus.ABORTED,
    },
    sentimentUserSpeaking: {
      id: null,
      status: null,
      dateCreated: null,
      description: null,
      companyName: null,
      companyPosition: null,
      seatCode: null,
      userCreatedName: null,
      userCreatedEmail: null,
      userCreatedId: null,
      startTime: null,
      endTime: null
    },
    fullNameUserVoice: null,
    endTimeActiveVoice: null
  }),
  computed: {
    ...mapGetters([
      "GET_SNACK_BAR",
      "SENTIMENT_DATA",
      "SENTIMENT_WITH_CURRENT_USER_DATA",
      "GET_CURRENT_ACCOUNT",
      "GET_SENTIMENT_USER_SPEAKING_DATA",
      "GET_LAST_ACTIVE_VOICE_DATA"
    ]),
  },
  watch: {
    SENTIMENT_DATA: function() {
      this.sentimentList = [];
      this.SENTIMENT_DATA &&
        this.SENTIMENT_DATA.forEach((element, index) => {
          let object = {
            stt: index + 1,
            email: element.user.email,
            fullname: element.user.last_name + " " + element.user.first_name,
            companyName: element.company.name ? element.company.name : "",
            companyPosition: element.company.position
              ? element.company.position
              : "",
            status: element.status,
            seatCode: element.seat_code,
          };
          this.sentimentList.push(object);
        });
      this.isLoading = false;
    },
    SENTIMENT_WITH_CURRENT_USER_DATA: function() {
      this.description = this.$t("sideBar.stated");
      let sentimentWithCurrentUserData = this.SENTIMENT_WITH_CURRENT_USER_DATA
        .data;
      if (!this.isNull(sentimentWithCurrentUserData)) {
        if (
          !this.isNull(sentimentWithCurrentUserData.start_time) &&
          !this.isNull(sentimentWithCurrentUserData.end_time)
        ) {
          // Calculate start, end time
          let start = new Date(
            dateUtils.formatDate(
              sentimentWithCurrentUserData.start_time,
              dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
              dateUtils.STATIC_FORMAT_DATE_TIME_SQL
            )
          );
          let end = new Date(
            dateUtils.formatDate(
              sentimentWithCurrentUserData.end_time,
              dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
              dateUtils.STATIC_FORMAT_DATE_TIME_SQL
            )
          );
          // Diff two date
          var timeRemaining = end.getTime() - start.getTime();
          // Convert milliseconds to hours, minutes, seconds
          let hoursMinutesSecondsObj = dateUtils.millisToHoursMinutesAndSeconds(
            timeRemaining
          );
          this.sentimentForCurrentUser["timeSpeak"] = hoursMinutesSecondsObj;
        } else {
          this.sentimentForCurrentUser["timeSpeak"] = null;
        }
        // Status
        this.sentimentForCurrentUser["status"] =
          sentimentWithCurrentUserData.status;
        // Id
        this.sentimentForCurrentUser["id"] = sentimentWithCurrentUserData.id;
        // Description
        this.sentimentForCurrentUser["description"] =
          sentimentWithCurrentUserData.description;
      } else {
        this.sentimentForCurrentUser = {
          id: null,
          status: null,
          dateCreated: null,
          description: null,
          timeSpeak: null,
        };
      }
    },
    GET_SENTIMENT_USER_SPEAKING_DATA: function () {
      let data = this.GET_SENTIMENT_USER_SPEAKING_DATA
      if (Object.keys(data).length > 0) {
        this.sentimentUserSpeaking.id = data.id
        this.sentimentUserSpeaking.status = data.status
        this.sentimentUserSpeaking.description = data.description
        this.sentimentUserSpeaking.seatCode = data.seat_code
        this.sentimentUserSpeaking.userCreatedName = data.user.last_name + ' ' + data.user.first_name
        this.sentimentUserSpeaking.userCreatedEmail = data.user.email
        this.sentimentUserSpeaking.companyName = data.company.name
        this.sentimentUserSpeaking.companyPosition = data.company.position
        this.sentimentUserSpeaking.userCreatedId = data.user.id
        this.sentimentUserSpeaking.startTime = 
          !functionUtils.validateStringIsNull(data.start_time)
            ? dateUtils.formatDate(
              data.start_time,
              dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
              dateUtils.STATIC_FORMAT_TIME_DATE
            ) : null
        this.sentimentUserSpeaking.endTime = 
          !functionUtils.validateStringIsNull(data.end_time)
            ? dateUtils.formatDate(
              data.end_time,
              dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
              dateUtils.STATIC_FORMAT_TIME_DATE
            ) : null
      } else {
        this.sentimentUserSpeaking = {
          id: null,
          status: null,
          dateCreated: null,
          description: null,
          companyName: null,
          companyPosition: null,
          seatCode: null,
          userCreatedName: null,
          userCreatedEmail: null,
          userCreatedId: null,
          startTime: null,
          endTime: null
        }
      }
    },
    GET_LAST_ACTIVE_VOICE_DATA: function() {
      let data = this.GET_LAST_ACTIVE_VOICE_DATA
      this.fullNameUserVoice = data.full_name_user
    }
  },
  methods: {
    validateStringIsNull: function(data) {
      return functionUtils.validateStringIsNull(data)
    },
    /**
     * Edit sentiment
     */
    onEditSentiment: function() {
      if (
        !functionUtils.isNull(this.sentimentForCurrentUser.id) &&
        this.sentimentForCurrentUser.status == SentimentStatus.WAITING
      ) {
        this.isEdit = true;
        this.isCancel = false;
        this.description = this.sentimentForCurrentUser.description;
      }
    },
    /**
     * Check null
     */
    isNull: function(data) {
      return functionUtils.isNull(data);
    },
    /**
     * Get sentiment for current user status
     */
    getSentimentForCurrentUserStatus: function(status) {
      if (functionUtils.isNull(this.sentimentForCurrentUser.status)) {
        return "stated.statusType.waiting";
      } else if (status == SentimentStatus.DONE) {
        return "stated.statusType.done";
      } else {
        return "stated.statusType.waiting";
      }
    },
    /**
     * Get status
     */
    getStatus: function(status) {
      if (status == SentimentStatus.DONE) {
        return "stated.statusType.done";
      } if (status == SentimentStatus.CRITICIZE) {
        return "stated.statusType.criticize";
      }if (status == SentimentStatus.DOING) {
        return "stated.statusType.doing";
      } else {
        return "stated.statusType.waiting";
      }
    },
    /**
     * Check form
     */
    checkForm: function() {
      this.fieldBlured = true;
      if (this.validRequire(this.description)) {
        this.onCreateSentiment();
      }
    },
    /**
     * Refresh data
     */
    refreshData: function() {
      let eventId = sessionStorage.getItem("event_id");
      let filter = {
        params: {
          eventId: eventId,
        },
      };
      this.GET_SENTIMENT_IN_EVENT(filter);
      this.GET_SENTIMENT_WITH_CURRENT_USER(filter);
      this.description = "";
      this.ON_SHOW_TOAST({
        message: this.$t("common.success"),
        styleType: "success",
      });
      this.isEdit = false;
      this.isCancel = false;
    },
    /**
     * Cancel sentiment
     */
    onCancelSentiment: function() {
      if (
        !functionUtils.isNull(this.sentimentForCurrentUser.id) &&
        this.sentimentForCurrentUser.status == SentimentStatus.WAITING
      ) {
        this.isEdit = false;
        this.isCancel = true;
        this.description = this.sentimentForCurrentUser.description;
      }
    },
    /**
     * Create sentiment
     */
    onCreateSentiment: function() {
      if (this.isEdit || this.isCancel) {
        let filter = {
          isEdit: this.isEdit,
          isAdminRole: false,
          status: SentimentStatus.CANCELLED,
          description: this.description,
        };
        this.UPDATE_SENTIMENT({
          id: this.sentimentForCurrentUser.id,
          ...filter,
        })
          .then(
            function() {
              this.isEdit = false;
              this.refreshData();
              this.getSentimentSpeaking()
              this.sendNotification();
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      } else {
        if (
          this.isNull(this.sentimentForCurrentUser.status) ||
          (!this.isNull(this.sentimentForCurrentUser.status) &&
            (this.sentimentForCurrentUser.status == SentimentStatus.DONE ||
              this.sentimentForCurrentUser.status ==
                SentimentStatus.CANCELLED ||
              this.sentimentForCurrentUser.status == SentimentStatus.ABORTED))
        ) {
          let eventId = sessionStorage.getItem("event_id");
          let filter = {
            eventId: eventId,
            status: SentimentStatus.WAITING,
            description: this.description,
          };
          this.CREATE_SENTIMENT_FOR_EVENT(filter)
            .then(
              function() {
                this.refreshData();
                this.getSentimentSpeaking()
                this.sendNotification();
              }.bind(this)
            )
            .catch(
              function() {
                this.ON_SHOW_TOAST({
                  message: this.$t("common.somethingWentWrong"),
                  styleType: "danger",
                });
              }.bind(this)
            );
        }
      }
    },
    /**
     * Valid require
     */
    validRequire: function(fieldRequire) {
      if (fieldRequire != "") {
        return true;
      } else {
        return false;
      }
    },
    sendNotification() {
      let data = {
        event_id: sessionStorage.getItem("event_id"),
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.USER_SEND_STATED,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.Stated,
        full_name:
          this.GET_CURRENT_ACCOUNT.lastname +
          " " +
          this.GET_CURRENT_ACCOUNT.firstname,
        stated_content: this.description,
      };

      let smsData = {
        event_id: sessionStorage.getItem("event_id"),
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.USER_SEND_STATED,
        template_type: Enum.TemplateType.SMS,
        full_name:
          this.GET_CURRENT_ACCOUNT.lastname +
          " " +
          this.GET_CURRENT_ACCOUNT.firstname,
        stated_content: this.description,
      };

      this.SEND_NOTIFICATION_IN_MEETING(data);
      this.SEND_SMS_IN_MEETING(smsData);
    },
    showModalCriticism: function() {
      this.$refs.criticismModal.onShowModal();
    },
    getSentimentSpeaking: function () {
      let filter = {
        params: {
          eventId: sessionStorage.getItem("event_id")
        },
      };
      this.GET_SENTIMENT_USER_SPEAKING(filter)
    },
    // time countdown
    checkShowVoice: function() {
      let data = this.GET_LAST_ACTIVE_VOICE_DATA
      if (Object.keys(data).length === 0) {
        return false
      } else {
        let expectedTime = !functionUtils.validateStringIsNull(data.expected_end_time)
          ? moment(data.expected_end_time) : null
        this.endTimeActiveVoice = !functionUtils.validateStringIsNull(expectedTime) 
          ? expectedTime.format("YYYY-MM-DD HH:mm:ss") : null
        if (moment() < expectedTime) return true
      }
      return false
    },
    callBackEndTimeCountDown: function() {

    },
    getLastActiveVoice: function() {
      let filter = {
        params: {
          eventId: sessionStorage.getItem("event_id")
        }
      }
      this.GET_LAST_ACTIVE_VOICE(filter)
    },
    ...mapActions([
      "CREATE_SENTIMENT_FOR_EVENT",
      "ON_SHOW_TOAST",
      "GET_SENTIMENT_IN_EVENT",
      "GET_SENTIMENT_WITH_CURRENT_USER",
      "UPDATE_SENTIMENT",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "GET_SENTIMENT_USER_SPEAKING",
      "GET_LAST_ACTIVE_VOICE"
    ]),
  },
  created() {
    let eventId = sessionStorage.getItem("event_id");
    this.isLoading = true;
    this.getLastActiveVoice()
    let filter = {
      params: {
        eventId: eventId,
        isSentiment: true,
      },
    };
    this.GET_SENTIMENT_WITH_CURRENT_USER(filter)
      .then(
        function() {
          this.GET_SENTIMENT_IN_EVENT(filter)
            .then(
              function() {
                // Todo
              }.bind(this)
            )
            .catch(
              function() {
                this.isLoading = false;
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function() {
          this.isLoading = false;
        }.bind(this)
      );
    this.getSentimentSpeaking()
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button-grey {
  background: #bdbdbd;
  color: #ffffff;
}
.submit-button-primary {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
  color: #fff;
}
</style>
