<template>
  <div style="">
    <flip-countdown
      :deadline="endTime"
      :labels="{}"
      :showDays="false"
      @timeElapsed="timeElapsedHandler"
    ></flip-countdown>
  </div>
</template>
<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  props: {
    endTime: {
      type: String,
      default: () => {
        return null;
      },
    },
  },
  components: {
    FlipCountdown,
  },
  methods: {
    timeElapsedHandler: function() {
      this.$emit("timeElapsedHandler");
    },
  },
};
</script>
<style lang="scss" scoped>
.flip-clock {
  padding: 0;
  width: fit-content !important;
  margin: 0 !important;
}
.flip-clock /deep/ .flip-clock__piece {
  margin: 0 2px;
}
.flip-clock /deep/ .flip-clock__piece .flip-clock__card {
  font-size: 16px;
}
.flip-clock /deep/ .flip-clock__piece .flip-clock__slot {
  font-size: 10px;
}
</style>
