<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('stated.criticismTitle')"
      centered
      id="modal-center"
      size="lg"
      no-close-on-backdrop
    >
      <div>
        <!-- Content register stated -->
        <div class="meeting-font-regular" style="text-align: left;">
          <span style="font-size: 16px;">{{
            $t("stated.enterContentCriticism")
          }}</span>
        </div>
        <div class="mt-3">
          <textarea
            v-model="criticismData.content"
            class="form-control meeting-font-regular"
            style="font-size: 16px;"
            :placeholder="$t('stated.inputContentCriticism')"
            rows="3"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validRequire(criticismData.content) && fieldBlured,
            }"
            v-on:blur="fieldBlured = true"
            :disabled="criticismData.id!==null && !isEdit"
          ></textarea>
          <div class="invalid-feedback" style="text-align: left;">
            {{ $t("stated.requireContentSpeak") }}
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100" v-if="!isShowCancel">
          <button
            v-if="criticismData.id!==null"
            size="sm"
            class="btn btn-outline-primary float-left mr-3"
            @click="isEdit=true"
            style="font-size: small;"
          >
            <img class="mr-1" src="../../assets/icons/edit.svg" />
            {{ $t('common.update') }}
          </button>
          <button
            @click="checkForm()"
            type="submit"
            :class="
              !isNull(criticismData.status) &&
              !isEdit &&
              !isCancel &&
              criticismData.status == sentimentStatus.WAITING
                ? 'btn submit-button-grey meeting-font-regular float-right'
                : 'btn submit-button-primary meeting-font-regular float-right'"
            style="font-size: small;"
          >
            {{
              isEdit
                ? $t("stated.update")
                : isCancel
                ? $t("stated.cancel")
                : $t("stated.sendRegister")
            }}
          </button>
          <button
            size="sm"
            class="btn btn-dark float-right mr-3"
            @click="isShow=false"
            style="font-size: small;"
          >
            {{ $t('common.close') }}
          </button>
        </div>
        <div class="w-100" v-else>
          <button
            v-if="criticismData.id!==null"
            size="sm"
            class="btn btn-outline-danger float-left mr-3"
            @click="isCancel=true"
            style="font-size: small;"
          >
            <img class="mr-1" src="../../assets/icons/close-red.svg" />
            {{ $t('stated.cancelRegister') }}
          </button>
          <button
            @click="checkForm()"
            type="submit"
            :class="
              !isNull(criticismData.status) &&
              !isEdit &&
              !isCancel &&
              criticismData.status == sentimentStatus.WAITING
                ? 'btn submit-button-grey meeting-font-regular float-right'
                : 'btn submit-button-primary meeting-font-regular float-right'"
            style="font-size: small;"
          >
            {{
              isEdit
                ? $t("stated.update")
                : isCancel
                ? $t("stated.cancel")
                : $t("stated.sendRegister")
            }}
          </button>
          <button
            size="sm"
            class="btn btn-dark float-right mr-3"
            @click="isShow=false"
            style="font-size: small;"
          >
            {{ $t('common.close') }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import SentimentStatus from "enum/sentimentStatus";
import functionUtils from "utils/functionUtils";
export default {
  data: () => ({
    isShow: false,
    criticismData: {
      id: null,
      content: null,
      status: null
    },
    sentimentId: null,
    fieldBlured: false,
    isEdit: false,
    isCancel: false,
    isShowCancel: false,
    sentimentStatus: SentimentStatus
  }),
  methods: {
    /**
     * Check null
     */
    isNull: function(data) {
      return functionUtils.isNull(data);
    },
    onShowModal: function(sentimentId, criticismData) {
      this.sentimentId = sentimentId
      this.isShow = true;
      this.criticismData = {
        id: criticismData.id,
        content: criticismData.description,
        status: criticismData.status
      }
      this.isEdit = false
      this.isCancel=false
    },
    onCancelRegister: function(criticismData) {
      this.isShow=true
      this.isEdit=false
      this.criticismData = {
        id: criticismData.id,
        content: criticismData.description,
        status: criticismData.status
      }
      this.isShowCancel=true
    },
    /**
     * Valid require
     */
    validRequire: function(fieldRequire) {
      if (fieldRequire != "" && fieldRequire != null) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Check form
     */
    checkForm: function() {
      this.fieldBlured = true;
      if (this.validRequire(this.criticismData.content)) {
        this.onCreateCriticism();
      }
    },
    onCreateCriticism: function () {
      let data = {
        id: this.criticismData.id,
        sentimentId: this.sentimentId,
        content: this.criticismData.content
      }
      if (this.criticismData.id === null && (!this.isEdit && !this.isCancel)) {
        data['status'] = SentimentStatus.WAITING
        this.CREATE_CRITICISM(data)
          .then(
            function() {
              this.isShow = false;
              this.$emit('getApiCriticismList', true)
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      } else {
        data['isEdit']=this.isEdit
        if (this.isCancel) {
          data['status'] = SentimentStatus.CANCELLED
        }
        this.UPDATE_CRITICISM(data)
          .then(
            function() {
              this.isEdit=false
              this.isCancel=false
              this.isShow = false;
              this.$emit('getApiCriticismList', true)
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    ...mapActions(['CREATE_CRITICISM', 'UPDATE_CRITICISM', 'ON_SHOW_TOAST'])
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button-grey {
  background: #bdbdbd;
  color: #ffffff;
}
.submit-button-primary {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
  color: #fff;
}
</style>
